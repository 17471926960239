// TODO: implement, remove all comment after done

import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    rounded: true;
    'round-outlined': true;
  }
  interface ButtonPropsColorOverrides {
    // gray: true;
  }
  // interface ButtonClasses {
  //   underline: string
  //   pill: string
  //   invertPill: string
  // }
}

interface MuiButtonComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiButton'];
  defaultProps?: ComponentsProps['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

export const MuiButtonComponent: MuiButtonComponentType = {
  variants: [],
  defaultProps: {
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ ownerState: { color }, theme }) => {
      return [
        {
          borderRadius: theme.shape.borderRadius * 5,
          color: theme.palette.text.primary,
        },
        color === 'info' && {
          color: theme.palette.getContrastText(theme.palette.info.main),
        },
      ];
    },
  },
};
