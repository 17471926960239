import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subcaption: React.CSSProperties;
    inputLabel: React.CSSProperties;
    inputValue: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subcaption?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    inputValue?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subcaption: true;
    inputLabel: true;
    inputValue: true;
  }
}

export const createTypography = (): ThemeOptions['typography'] => {
  const fonts = ['Josefin Sans'];
  return {
    fontFamily: fonts.map((font) => `"${font}"`).join(', '),
    button: {
      fontWeight: 400,
      textTransform: 'none',
    },
    // h6: {
    //   fontSize: '1.15rem',
    // },
    // h5: {
    //   fontSize: '1.45rem',
    // },
  };
};
