import React from 'react';
import { Stack, Typography } from '@mui/material';
import { FacebookLogo, InstagramLogo, TiktokLogo } from 'phosphor-react';

interface FooterProps {}

export const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography flex={1} textTransform="uppercase">
        &copy; Grafachy 2022
      </Typography>
      <Stack direction="row" pr={15} gap={3}>
        <InstagramLogo size={24} />
        <FacebookLogo size={24} />
        <TiktokLogo size={24} />
      </Stack>
    </Stack>
  );
};

Footer.displayName = 'Footer';
