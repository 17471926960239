import create from 'zustand';

type AuthStore = {
  isLogin: boolean;
  setIsLogin: (value: boolean) => void;
};

export const useAuthStore = create<AuthStore>((set) => {
  return {
    isLogin: false,
    setIsLogin: (value) => set({ isLogin: value }),
  };
});
