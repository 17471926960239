import React, { PropsWithChildren } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { Box } from '@mui/material';

import Footer from './components/footer';
import Header from './components/header';
import NavigationMenu from './components/navigation-menu';

interface DefaultLayoutProps {}

export const DefaultLayout: React.FunctionComponent<PropsWithChildren<DefaultLayoutProps>> = ({
  children,
}) => {
  const editorMatch = useMatch('/editor/*');

  return (
    <Box
      display="flex"
      minHeight="100vh"
      flexDirection="column"
      py={3}
      sx={{
        px: { lg: 10, xs: 5 },
        gap: {
          lg: 3,
          xs: 2,
        },
        ...(editorMatch && {
          height: '100vh',
        }),
      }}
    >
      <Header />
      <NavigationMenu />
      <Box
        flex={1}
        sx={{ px: { lg: editorMatch ? 5 : 20, xs: editorMatch ? 2 : 15 } }}
        overflow={editorMatch ? 'hidden' : 'unset'}
      >
        {children || <Outlet />}
      </Box>
      <Footer />
    </Box>
  );
};

DefaultLayout.displayName = 'DefaultLayout';
