import React, { FunctionComponent, Suspense } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import DefaultLayout from '../components/layouts/default-layout';
import { LoadingPanel } from '../components/loading-panel';
import { ThemeProvider } from '../providers/theme-provider';

const ChannelPage = React.lazy(() => import('./channel'));
const FeedPage = React.lazy(() => import('./feed'));
const DiscoverPage = React.lazy(() => import('./discover'));
const CreatePage = React.lazy(() => import('./create'));
const EditorPage = React.lazy(() => import('./editor'));
const AboutPage = React.lazy(() => import('./about'));
const FeaturesPage = React.lazy(() => import('./features'));
const ContactPage = React.lazy(() => import('./contact'));
const MembershipPage = React.lazy(() => import('./membership'));
const LoginPage = React.lazy(() => import('./login'));
const ResetPasswordPage = React.lazy(() => import('./reset-password'));
const RegisterPage = React.lazy(() => import('./register'));
const BenefitPage = React.lazy(() => import('./benefit'));

const routes: RouteObject[] = [
  {
    element: <ThemeProvider name="experiment">{<DefaultLayout />}</ThemeProvider>,
    children: [
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/register',
        element: <RegisterPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: '/features',
        element: <FeaturesPage />,
      },
      {
        path: '/membership',
        element: <MembershipPage />,
      },
      {
        path: '/membership/benefit',
        element: <BenefitPage />,
      },
    ],
  },
  {
    element: <ThemeProvider />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: '/',
            element: <AboutPage />,
          },

          {
            path: '/contact',
            element: <ContactPage />,
          },
          {
            path: '/feed',
            element: <FeedPage />,
          },
          {
            path: '/discover',
            element: <DiscoverPage />,
          },
          {
            path: '/channel',
            element: <ChannelPage />,
          },
          {
            path: '/create',
            element: <CreatePage />,
          },
          {
            path: '/editor/:editorId',
            element: <EditorPage />,
          },
          {
            path: '/editor/',
            element: <EditorPage />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const Routes: FunctionComponent = () => {
  const routesElement = useRoutes(routes);
  return <Suspense fallback={<LoadingPanel variant="linear" />}>{routesElement}</Suspense>;
};

Routes.displayName = 'Routes';

export default Routes;
