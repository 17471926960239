import React from 'react';
import { useMatch } from 'react-router-dom';

import NavButton from '../nav-button';

interface OnboardingMenuProps {}

export const OnboardingMenu: React.FunctionComponent<OnboardingMenuProps> = () => {
  const aboutMatch = useMatch('/*');
  const featuresMatch = useMatch('/features/*');
  const membershipMatch = useMatch('/membership/*');
  const contactMatch = useMatch('/contact/*');

  return (
    <>
      <NavButton
        to="/"
        active={!!aboutMatch && !featuresMatch && !membershipMatch && !contactMatch}
      >
        About
      </NavButton>
      <NavButton to="features" active={!!featuresMatch}>
        Features
      </NavButton>
      <NavButton to="membership" active={!!membershipMatch}>
        Membership
      </NavButton>
      <NavButton to="contact" active={!!contactMatch}>
        Contact
      </NavButton>
    </>
  );
};

OnboardingMenu.displayName = 'OnboardingMenu';
