import createMuiTheme from '@mui/material/styles/createTheme';

import components from './components';
import { createPalette } from './palette';
import { createTypography } from './typography';

interface ElementTheme {
  fontFamily: string;
  fontSize: string | number;
  color: string;
  backgroundColor: string;
  boxShadow?: boolean;
}

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    element?: ElementTheme;
  }
  interface Theme {
    element?: ElementTheme;
  }
}

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {}
}

export type ThemeMode = 'dark' | 'light';

export function createTheme(mode: ThemeMode = 'light') {
  return createMuiTheme({
    palette: createPalette(mode),
    typography: createTypography(),
    // shape: createShape(),
    components,
    // zIndex,
  });
}
