import React from 'react';
import { useMatch } from 'react-router-dom';

import NavButton from '../nav-button';

interface EditorMenuProps {}

export const EditorMenu: React.FunctionComponent<EditorMenuProps> = () => {
  const feedMatch = useMatch('/feed/*');
  const discoverMatch = useMatch('/discover/*');
  const channelMatch = useMatch('/channel/*');
  const createMatch = useMatch('/create/*');

  return (
    <>
      <NavButton to="feed" active={!!feedMatch}>
        Feed
      </NavButton>
      <NavButton to="discover" active={!!discoverMatch}>
        Discover
      </NavButton>
      <NavButton to="channel" active={!!channelMatch}>
        My channel
      </NavButton>
      <NavButton to="create" active={!!createMatch}>
        Create
      </NavButton>
    </>
  );
};

EditorMenu.displayName = 'EditorMenu';
