import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Stack } from '@mui/material';
import { BellSimple, User } from 'phosphor-react';

import LogoSvg from '../../../../../assets/logo.svg';
import { useAuthStore } from '../../../../../store/use-auth-store';

interface HeaderProps {}

export const Header: React.FunctionComponent<HeaderProps> = () => {
  const navigate = useNavigate();
  const isLogin = useAuthStore((state) => state.isLogin);

  return (
    <Stack direction="row" alignItems="center">
      <Box flex={1} ml="50%" sx={{ transform: 'translateX(-50%)' }}>
        <img src={LogoSvg} alt="logo" />
      </Box>
      <Stack direction="row" gap={1} pr={15}>
        {isLogin && (
          <IconButton size="small">
            <BellSimple size={24} />
          </IconButton>
        )}

        <IconButton size="small" onClick={() => navigate('login')}>
          <User size={24} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

Header.displayName = 'Header';
