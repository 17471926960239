import { PaletteOptions } from '@mui/material';

import type { ThemeMode } from './index';

export type Color = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

const createSharedOptions = (mode: ThemeMode): PaletteOptions => ({
  mode,
  primary: {
    main: '#BFAFAF',
  },
  secondary: {
    main: '#D9D9D9',
  },
  info: {
    main: '#0F1B27',
  },
  error: {
    main: '#d32f2f',
  },
  warning: {
    main: '#FBA913',
  },
  success: {
    main: '#1AD598',
  },

  background: {
    default: '#F5F5F5',
  },
});

export const createPalette = (mode: ThemeMode): PaletteOptions => ({
  mode,
  ...createSharedOptions(mode),
});
