import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from './lib/react-query';
import { ThemeProvider } from './providers/theme-provider';
import Routes from './routes';

export function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ReactFlowProvider>
            <Routes />
          </ReactFlowProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
