import { styled } from '@mui/material';

export const ItemContent = styled('div', {
  shouldForwardProp: (propName) => propName !== 'active',
})<{ active?: boolean }>(
  ({ active }) =>
    active && {
      textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
    },
  ({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
  })
);
