import React from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';

import { Wrapper } from './loading-panel.styled';

interface LoadingPanedProps {
  variant?: 'linear' | 'circular';
}

export const LoadingPanel: React.FunctionComponent<LoadingPanedProps> = ({ variant }) =>
  variant === 'circular' ? (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  ) : (
    <LinearProgress />
  );
LoadingPanel.displayName = 'LoadingPanel';
