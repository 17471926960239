import React from 'react';
import { To } from 'react-router-dom';

import { NavItem } from '../../navigation-menu.styled';
import { ItemContent } from './nav-button.styled';

interface NavButtonProps {
  to: To;
  active: boolean;
}

export const NavButton: React.FunctionComponent<React.PropsWithChildren<NavButtonProps>> = ({
  to,
  active,
  children,
}) => {
  return (
    <NavItem to={to}>
      <ItemContent active={active}>{children}</ItemContent>
    </NavItem>
  );
};

NavButton.displayName = 'NavButton';
