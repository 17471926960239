import React from 'react';
import { Stack } from '@mui/material';

import { useAuthStore } from '../../../../../store/use-auth-store';

import { EditorMenu } from './components/editor-menu';
import { OnboardingMenu } from './components/onboarding-menu';

interface NavigationMenuProps {}

export const NavigationMenu: React.FunctionComponent<NavigationMenuProps> = () => {
  const isLogin = useAuthStore((state) => state.isLogin);
  return (
    <Stack direction="row" justifyContent="center" gap={12}>
      {isLogin ? <EditorMenu /> : <OnboardingMenu />}
    </Stack>
  );
};

NavigationMenu.displayName = 'NavigationMenu';
