import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
`;
