import { ThemeOptions } from '@mui/material';

import { MuiButtonComponent } from './mui-button.component';
import { MuiLinkComponent } from './mui-link.component';

const components: ThemeOptions['components'] = {
  MuiButton: MuiButtonComponent,
  MuiLink: MuiLinkComponent,
};

export default components;
